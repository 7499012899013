import { styled } from "../../theme/styled";

export const StyledPlayer = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "12px",
  shadow: 2,
  borderRadius: 16,
  marginBottom: 8,
  "&:first-child": {
    marginTop: 8,
  },
});

export const StyledPlayerName = styled("span", {
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "22px",
});

export const StyledPlayerCity = styled("span", {
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "22px",
});
