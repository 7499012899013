import { collection, doc, getDoc, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from ".";

const getRankings = async (clubId) => {
  const q = query(collection(db, "rankings"), where(`clubs.${clubId}`, "==", "owner"));
  const querySnapshot = await getDocs(q);
  const rankings = [];

  for (let i = 0; i < querySnapshot.docs.length; i++) {
    const doc = querySnapshot.docs[i];
    const data = doc.data();
    rankings.push({
      id: doc.id,
      ...data,
    });
  }
  return rankings;
};

const getRanking = async (rankingId) => {
  const rankingRef = doc(db, "rankings", rankingId);
  const rankingDoc = await getDoc(rankingRef);
  let result;
  if (rankingDoc.exists()) {
    result = {
      id: rankingDoc.id,
      ...rankingDoc.data(),
    };
  } else {
    result = undefined;
  }
  return result;
};

const getCategories = async (rankingId) => {
  const q = query(collection(db, "rankings", rankingId, "categories"));
  const querySnapshot = await getDocs(q);
  const categories = [];

  for (let i = 0; i < querySnapshot.docs.length; i++) {
    const doc = querySnapshot.docs[i];
    const data = doc.data();
    categories.push({
      id: doc.id,
      ...data,
    });
  }
  return categories;
};

const getPlayers = async (rankingId) => {
  const q = query(collection(db, "rankings", rankingId, "players"), orderBy("name"));
  const querySnapshot = await getDocs(q);
  const players = [];

  for (let i = 0; i < querySnapshot.docs.length; i++) {
    const doc = querySnapshot.docs[i];
    const data = doc.data();
    players.push({
      id: doc.id,
      ...data,
    });
  }
  return players;
};

export { getRankings, getCategories, getPlayers, getRanking };
