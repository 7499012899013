import React, { useEffect, useMemo, useState } from "react";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import { useParams } from "react-router";
import { getClub } from "../firebase/clubs";
import TextInput from "../components/TextInput/TextInput";
import { getCategories, getPlayers, getRanking } from "../firebase/rankings";
import SmallLoader from "../components/SmallLoader/SmallLoader";
import Accordion from "../components/Accordion/Accordion";
import CardPlayer from "../components/CardPlayer/CardPlayer";
import ScrollLayout from "../components/ScrollLayout/ScrollLayout";
import Container from "../components/Container/Container";
import removeAccents from "remove-accents";

const RankingPage = () => {
  const { clubId, rankingId } = useParams();
  const [club, setClub] = useState(null);
  const [ranking, setRanking] = useState([]);
  const [categories, setCategories] = useState([]);
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchPlayer, setSearchPlayer] = useState("");
  const [filteredPlayers, setFilteredPlayers] = useState([]);

  useEffect(() => {
    const promises = [];
    promises.push(getClub(clubId).then((data) => setClub(data)));
    promises.push(getRanking(rankingId).then((data) => setRanking(data)));
    promises.push(getCategories(rankingId).then((data) => setCategories(data)));
    promises.push(getPlayers(rankingId).then((data) => setPlayers(data)));
    Promise.all(promises)
      .then(() => setLoading(false))
      .catch(() => console.error("Error al obtener los datos"));
  }, [clubId, rankingId]);

  useEffect(() => {
    if (searchPlayer === "") {
      setFilteredPlayers(players);
    } else {
      setFilteredPlayers(
        players.filter((player) =>
          removeAccents(player.name.toLowerCase()).includes(removeAccents(searchPlayer.toLowerCase()))
        )
      );
    }
  }, [players, searchPlayer]);

  const visibleCategories = useMemo(
    () => categories.filter((category) => filteredPlayers.some((player) => player.category.id === category.id)),
    [categories, filteredPlayers]
  );

  const isSearching = !!searchPlayer.trim();
  const listKey = isSearching ? "search" : "default";

  if (loading) return <SmallLoader visible />;

  return (
    <ScrollLayout>
      <ScrollLayout.FixedPart>
        <Container>
          <Breadcrumb
            pages={[
              { label: club?.name, url: `/club/${club?.id}`, name: "club" },
              { label: ranking.name, name: "ranking" },
            ]}
          ></Breadcrumb>
          <TextInput
            search
            placeholder={"Buscar jugador..."}
            value={searchPlayer}
            onChange={(e) => {
              setSearchPlayer(e.target.value);
            }}
          ></TextInput>
        </Container>
      </ScrollLayout.FixedPart>
      <ScrollLayout.ScrollPart>
        <Container key={listKey}>
          {visibleCategories?.map((category) => (
            <Accordion key={category.id} title={category.name} defaultOpen={isSearching}>
              {filteredPlayers
                .filter((player) => player.category.id === category.id)
                .map((player) => (
                  <CardPlayer key={player.id} name={player.name} city={player.city} />
                ))}
            </Accordion>
          ))}
        </Container>
      </ScrollLayout.ScrollPart>
    </ScrollLayout>
  );
};

export default RankingPage;
