import React from "react";
import { StyledPlayer, StyledPlayerCity, StyledPlayerName } from "./CardPlayer.styles";

const CardPlayer = ({name, city}) => {
  return (
    <StyledPlayer>
      <StyledPlayerName>{name}</StyledPlayerName>
      <StyledPlayerCity>{city}</StyledPlayerCity>
    </StyledPlayer>
  );
};

export default CardPlayer;
